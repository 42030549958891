
import { GlueFrame } from '../libs/glueframe';

export function setupVideos(selector = '[data-action^="video"]') {
    const videos = document.body.querySelectorAll('.video.module');

    Array.from(videos).forEach(module => {
        const iframe = module.querySelector('iframe');

        if(iframe) {
            const player = new GlueFrame(iframe, 'Player');

            module.addEventListener('click', (event) => {
                player.set('playing', true);
                module.classList.add('started');
            });
        }
    });
}
