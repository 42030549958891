import { stickyNavOnScroll } from '../utils/stickyNavOnScroll';
import { enableScrollLock, disableScrollLock } from '../utils/scrollLock';

export let nav;

export function toggleMenuOpen() {
    if (nav.classList.contains('nav--open')) {
        disableScrollLock();
        nav.classList.remove('nav--open');

        if (window.pageYOffset > 50) {
            setTimeout(() => nav.classList.add('going-up'), 40);
        }

    } else {
        enableScrollLock();
        nav.classList.add('nav--open');
    }
}

export function setupNav(selector = '.nav', sticky = true) {
    nav = document.body.querySelector(selector);

    if (nav) {
        const navBtn = nav.querySelector('.nav__btn');

        navBtn.addEventListener('click', toggleMenuOpen);

        if(navBtn && window.getComputedStyle(navBtn).display !== 'none') {
            const navExpanders = nav.querySelectorAll('.expander');

            Array.from(navExpanders).forEach(expander => {
                const url = expander.getAttribute('href');
                const text = expander.innerText;

                const newListItem = document.createElement('li');
                let newLink = document.createElement('a');
                newLink.setAttribute('href', url);
                newLink.innerText = text.toLowerCase();

                newListItem.append(newLink);

                const sub = expander.nextElementSibling;

                sub.prepend(newListItem);

                expander.addEventListener('click', (event) => {
                    event.preventDefault();

                    sub.classList.toggle('open');
                    let height = sub.scrollHeight + 20;

                    if(sub.classList.contains('open')) {
                        sub.style.height = height + 'px';
                    } else {
                        sub.removeAttribute('style');
                    }
                })
            });
        }

        if (sticky) {
            stickyNavOnScroll(nav, 'nav--sticky', 30, 'going-up');
        }

        const search = nav.querySelector('.nav__search');

        if(search) {
            const searchSvg = search.querySelector('svg');
            const searchClose = search.querySelector('.close');

            if (searchSvg) {
                searchSvg.addEventListener('click', (event) => {
                    search.classList.add('opened');
                    search.querySelector('input').focus();
                    enableScrollLock();
                });
            }

            if (searchClose) {
                searchClose.addEventListener('click', (event) => {
                    search.classList.remove('opened');
                    disableScrollLock();
                });
            }
        }
    }
}
