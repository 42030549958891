import { onWindowResize, currentBreakpoint, currentBreakpointIndex } from './utils/windowResize';
import { onScroll, scrollTop } from './utils/scroll';
import { onReady } from './utils/onReady';
import { setupNav } from './components/nav';
import { setupLazyLoading } from './utils/lazyImage';
import { setupAccordions } from './components/accordion';
import { setupVideos } from './components/video';
import { initVhUnitOverwrite, debounce } from './utils/helpers';
import { version } from '../../package.json';
import { setupAnchorLinkScroll } from './components/anchors';
import { setupFilters } from './components/filter';
import { setupOverlay } from './components/overlay';
import { setupTabs } from './components/tabs';
import { setupYTVideos } from './components/video-yt';
import { setupSliders } from './components/slider';
import { watchFormFields } from './components/form';
import { setupGlueFrame } from './libs/glueframe';


// Below are example of how to use import libraries installed via npm.
// import sal from 'sal.js';

function init() {
    document.body.classList.remove('standby');

    setupAnchorLinkScroll();

    setupNav('.nav');

    onReady(() => {
        initVhUnitOverwrite();

        setupLazyLoading();

        setupAccordions();

        setupFilters();

        setupOverlay();

        // GlueFrame is used for videos
        setupGlueFrame();
        setupVideos();

        setupYTVideos();

        setupTabs();

        setupSliders();

        watchFormFields();
    });
}

init();
