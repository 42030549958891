/**
 * A utility to **lock the viewport** at the current position in order to **stop scrolling**.
 *
 * @example <caption>Basic usage</caption>
 * import { enableScrollLock, disableScrollLock } from './utils/dom/scrollLock';
 *
 * enableScrollLock();
 * window.setTimeout(disableScrollLock, 3000);
 */

import { getElementScroll } from './elementProperties';

const className = 'scroll-lock';
let scrollTop = 0;

/**
 * Get the current state of the scroll lock. `true` if the scroll lock is enabled, otherwise `false`.
 *
 * @type {boolean}
 */
export let scrollLocked = false;

/**
 * Enable the scroll lock.
 */
export function enableScrollLock() {
    if (!scrollLocked) {
        // Get scroll position
        const scrollPosition = getElementScroll();

        // Reset scroll position
        window.scrollTo(scrollPosition.left, 0);

        const htmlTag = document.documentElement;
        htmlTag.classList.add(className);
        htmlTag.style.marginTop = `${-scrollPosition.top}px`;
        htmlTag.style.position = 'fixed';
        htmlTag.style.overflow = 'hidden';
        htmlTag.style.width = '100%';
        document.body.style.overflowY = 'scroll';

        // Remember state
        scrollLocked = true;
        scrollTop = scrollPosition.top;
    }
}

/**
 * @type {function}
 * @ignore
 */
export const enable = enableScrollLock;

/**
 * Disable the scroll lock
 */
export function disableScrollLock() {
    if (scrollLocked) {
        const scrollPosition = getElementScroll();

        const htmlTag = document.documentElement;
        htmlTag.classList.remove(className);
        htmlTag.style.marginTop = '';
        htmlTag.style.position = '';
        htmlTag.style.overflow = '';
        htmlTag.style.width = '';
        document.body.removeAttribute('style');
        // Set the scroll position to what it was before
        window.scrollTo(scrollPosition.left, scrollTop);

        // Remember state
        scrollLocked = false;
    }
}

/**
 * @type {function}
 * @ignore
 */
export const disable = disableScrollLock;

/**
 * Toggle the scroll lock between on and off
 */
export function toggleScrollLock() {
    if (scrollLocked) {
        disableScrollLock();
    } else {
        enableScrollLock();
    }
}

/**
 * @type {function}
 * @ignore
 */
export const toggle = toggleScrollLock;

export default {
    enable,
    disable,
    toggle
};
