/*global YT */
import { isElement } from '../utils/elementProperties';
import { allowStatCookies } from '../utils/helpers';

let loadingVideoId;
let activePlayer;

function loadYouTubeApi() {
    const tag = document.createElement('script');

    tag.src = `${window.location.protocol}//www.youtube.com/iframe_api`;
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
}

/**
 * Sets up YouTube Player via YT API using YouTube id
 * Does the following as video is ready:
 * Forces hd720 quality
 * Mutes video
 * Autoplays video
 *
 * @param {string} youtubeId - YouTube video ID
 * @param {boolean} allowCookies - Set to false to force use of youtube-nocookie
 */

function generateYoutubeIframe(youtubeId, allowCookies = true) {
    const youtubeContainer = document.getElementById(youtubeId);

    if (youtubeContainer) {
        let ytPlayerHost = 'https://www.youtube-nocookie.com';

        if (allowStatCookies && allowCookies) {
            ytPlayerHost = 'https://www.youtube.com';
        }

        void new YT.Player(youtubeId, {
            videoId: youtubeId,
            host: ytPlayerHost,
            playerVars: {
                autoplay: 1,
                rel: 0,
                controls: 0,
                muted: 1
            },

            events: {
                onReady: event => {
                    event.target.setPlaybackQuality('hd720');
                    event.target.mute();
                    event.target.playVideo();
                },
                onStateChange: event => {
                    const YTP = event.target; // YouTube Player
                    const resumePlayback = function(e) {
                        e.preventDefault();

                        if (activePlayer) {
                            activePlayer.playVideo();
                        }
                    };

                    if (event.data === YT.PlayerState.BUFFERING) {
                        // Force better quality video
                        YTP.setPlaybackQuality('hd720');
                    }

                    // TODO: Review if pause abillity makes sense in this module

                    // Sets pause class
                    // By default this hides video iframe and shows media__content

                    if (event.data === YT.PlayerState.PAUSED) {
                        if (YTP.a) {
                            activePlayer = YTP;
                            YTP.a.parentNode.addEventListener('click', resumePlayback);
                            YTP.a.parentNode.classList.add('video--paused');
                        }
                    } else if (event.data === YT.PlayerState.PLAYING) {
                        if (YTP.a && YTP.a.parentNode.classList.contains('video--paused')) {
                            YTP.a.parentNode.classList.remove('video--paused');
                            YTP.a.parentNode.removeEventListener('click', resumePlayback);
                        } else {
                            onIframeReady(YTP.a.parentNode);

                            // Fix to black screen on loop in background-video
                            // Bug found in Chrome 78 and Safari 13
                            // ---------------------------------------------------
                            const remains = YTP.getDuration() - YTP.getCurrentTime();

                            if (window.rewindTO) {
                                clearTimeout(window.rewindTO);
                            }

                            window.rewindTO = setTimeout(() => {
                                YTP.seekTo(0);
                            }, (remains - 0.01) * 1000);
                        }
                    } else if (event.data === YT.PlayerState.ENDED) {
                        YTP.playVideo();
                    }
                }
            }
        });
    }
}

/**
 * Static standard youtube ready function
 * run from YouTube Iframe API when it is done loading
 *
 */

window.onYouTubeIframeAPIReady = function() {
    if (loadingVideoId) {
        generateYoutubeIframe(loadingVideoId);
    }
};

function onIframeReady(container) {
    container.classList.remove('video--loading');
    container.classList.add('video--loaded');
}

function extractVideoIdFromUrl(url) {
    const videoIdRegExp = /^.*((youtu.be\/|vimeo.com\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url.match(videoIdRegExp);

    if (match && match[7]) {
        return match[7];
    } else {
        console.warn(url, 'Invalid Video URL');
    }
}

/**
 * Loads iframe video inside video target
 * in element or data-target attribute depending on given trigger
 *
 * Generates YouTube or Vimeo iframe detected in videoLink set as data-video-link.
 *
 * @param {obejct|HTMLElement|string} trigger - event obj, element or selector string of video component
 */

export function initIframeVideoBg(trigger) {
    let videoTrigger;

    if (trigger) {
        if (isElement(trigger)) {
            videoTrigger = trigger;
        } else if (typeof trigger === 'object') {
            trigger.preventDefault();
            videoTrigger = trigger.target;
            videoTrigger.removeEventListener('click', initIframeVideoBg);
        } else if (typeof trigger === 'string') {
            videoTrigger = document.body.querySelector(trigger);
        } else {
            window.console.warn('trigger in initIframeVideoBg is wrong type of thing');
        }
    } else {
        window.console.warn(
            `Type: ${typeof trigger} not compatiple with initIframeVideoBg.. what is this kind of nonsans ?`
        );
    }

    const videoTarget = videoTrigger.getAttribute('data-target')
        ? document.getElementById(videoTrigger.getAttribute('data-target'))
        : videoTrigger;

    if (videoTarget) {
        const videoLink = videoTrigger.href ? videoTrigger.href : videoTrigger.getAttribute('data-video-link');

        if (videoLink) {
            const videoId = videoLink.indexOf('/') !== -1 ? extractVideoIdFromUrl(videoLink) : videoLink;
            const player = document.createElement('div');
            player.id = videoId;
            videoTarget.appendChild(player);
            videoTarget.classList.add('video--loading');

            if (!window.YT) {
                loadingVideoId = videoId;

                loadYouTubeApi();
            } else {
                generateYoutubeIframe(videoId, false);
            }
        }
    }
}

/**
 * Sets up Video components found
 * As click eventListener or direct inizialiser depending on 'data-target'
 * Note - Will only run if given selector elements are found in DOM
 *
 * @param {string} selector - element selector string
 */
export function setupYTVideos(selector = '[data-action^="youtube-video"]') {
    const videoElems = document.body.querySelectorAll(selector);

    for (let i = 0; i < videoElems.length; i++) {
        const videoElem = videoElems[i];

        if (videoElem.getAttribute('data-target')) {
            videoElem.addEventListener('click', initIframeVideoBg);
        } else {
            initIframeVideoBg(videoElem);
        }
    }
}
