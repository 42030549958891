export const transitionEndEventName = detectStyleDeclatationName();
export const animationEndEventName = detectStyleDeclatationName('animation');

/**
 * Detects which prefixed eventName is in CSSStyleDeclaration
 *
 * @param {string} eventTypeName - choose 'transition' or 'animation'
 * @returns {string}
 */
export function detectStyleDeclatationName(eventTypeName = 'transition') {
    const transitions = {
        transition: `${eventTypeName}end`,
        OTransition: `o${eventTypeName}end`,
        MozTransition: `${eventTypeName}end`,
        WebkitTransition: `webkit${eventTypeName}end`
    };

    const el = document.createElement('div');

    for (const t in transitions) {
        if (el.style[t] !== undefined) {
            return transitions[t];
        }
    }
    return '';
}

/**
 *
 * @param {HTMLElement} container - container to animate height of
 * @param {HTMLElement} classTarget [default = container] - target element that gets class
 * @param {Boolean} classOnBoth [default = false] - set class on both container and classTarget
 */
export function cssAnimateNewHeight(container, classTarget = container, classOnBoth = false) {
    let newHeight;
    const prevHeight = container.offsetHeight;

    const delayedClose = () => {
        if (classOnBoth) {
            container.classList.remove('open');
            classTarget.classList.remove('open');
        } else {
            classTarget.classList.remove('open');
        }

        container.removeEventListener(transitionEndEventName, delayedClose);
    };

    container.removeAttribute('style');

    if (classTarget.classList.contains('open')) {
        newHeight = 0;
        container.addEventListener(transitionEndEventName, delayedClose);

        if (classOnBoth) {
            classTarget.classList.remove('open');
        }
    } else {
        classTarget.classList.add('open');

        if (classOnBoth) {
            container.classList.add('open');
        }

        newHeight = container.offsetHeight + 20;
    }

    container.style.height = `${prevHeight}px`;

    setTimeout(() => {
        container.style.height = `${newHeight}px`;
    }, 10);
}